exports.components = {
  "component---src-pages-bakery-tsx": () => import("./../../../src/pages/bakery.tsx" /* webpackChunkName: "component---src-pages-bakery-tsx" */),
  "component---src-pages-drink-tsx": () => import("./../../../src/pages/drink.tsx" /* webpackChunkName: "component---src-pages-drink-tsx" */),
  "component---src-pages-fun-tsx": () => import("./../../../src/pages/fun.tsx" /* webpackChunkName: "component---src-pages-fun-tsx" */),
  "component---src-pages-gym-tsx": () => import("./../../../src/pages/gym.tsx" /* webpackChunkName: "component---src-pages-gym-tsx" */),
  "component---src-pages-hair-tsx": () => import("./../../../src/pages/hair.tsx" /* webpackChunkName: "component---src-pages-hair-tsx" */),
  "component---src-pages-ice-tsx": () => import("./../../../src/pages/ice.tsx" /* webpackChunkName: "component---src-pages-ice-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-swim-tsx": () => import("./../../../src/pages/swim.tsx" /* webpackChunkName: "component---src-pages-swim-tsx" */)
}

